import React, { useState } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import parse from 'html-react-parser'
import joinClasses from '/src/functions/joinClasses'
import scrollTo from 'gatsby-plugin-smoothscroll'
import './styles.scss'

const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const ContentDisclaimer = loadable(() => import('/src/components/Basic/ContentDisclaimer'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const ImageVideoSwap = loadable(() => import('/src/components/Base/ImageVideoSwap'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))
const ToggleButton = loadable(() => import('/src/components/Basic/Buttons/ToggleButton'))

const ContentWithMediaGrid = ({
    backgroundColour,
    titleArea,
    content,
    addInfoPerItem,
    itemsRepeater,
    addCtaOrExpansionToggle,
    buttonType,
    expandContent,
    callToAction,
    addSmallprint,
    smallprint,
    className,
    utm
}) => {
    const [isExpanded, setToggleExpansion] = useState(false)
    const [itemAmount, setItemAmount] = useState(Number(expandContent?.initialItems) || '')
    const [toggleButtonText, setToggleButtonText] = useState(expandContent?.moreItemsButtonText || '')

    const toggleContent = () => {
        setToggleExpansion((prev) => !prev)
        setItemAmount(!isExpanded ? itemsRepeater.length : Number(expandContent.initialItems))
        setToggleButtonText(!isExpanded ? expandContent.lessItemsButtonText : expandContent.moreItemsButtonText)
        if (isExpanded) {
            scrollTo('#content-with-media-grid')
        }
    }

    const classes = ['c-content-with-media-grid', ...(className ? [className] : [])]

    const concatenatedClasses = joinClasses(classes)

    const itemContent = (node, index) => {
        return (
            <div key={index} className="col-sm-6 col-md-4 c-content-with-media-grid__item">
                <ImageVideoSwap
                    image={node.image}
                    video={node.video}
                    mediaType={node.mediaType}
                    className={'c-content-with-media-grid__item-media'}
                />

                {addInfoPerItem && (
                    <div className="c-content-with-media-grid__item-container">
                        <h3 className="c-content-with-media-grid__item-title">{node.title}</h3>

                        <div className="c-content-with-media-grid__item-content">{parse(node.content)}</div>
                    </div>
                )}
            </div>
        )
    }

    return (
        <Section
            id={'content-with-media-grid'}
            variant={backgroundColour}
            titleArea={titleArea}
            className={concatenatedClasses}
        >
            {content && (
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">{parse(content)}</div>
                </div>
            )}

            {itemsRepeater && (
                <div className="row c-content-with-media-grid__content-row">
                    {addCtaOrExpansionToggle && buttonType === 'toggle'
                        ? itemsRepeater.slice(0, itemAmount).map((node, index) => itemContent(node, index))
                        : itemsRepeater.map((node, index) => itemContent(node, index))}
                </div>
            )}

            {addCtaOrExpansionToggle && buttonType === 'toggle' ? (
                <CtaContainer align={'centered'}>
                    <ToggleButton toggleState={isExpanded} onClick={toggleContent}>
                        {toggleButtonText}
                    </ToggleButton>
                </CtaContainer>
            ) : addCtaOrExpansionToggle && buttonType === 'cta' ? (
                <CtaContainer align={'centered'}>
                    <ButtonLink data={callToAction} size={'lg'} icon={'arrow'} utm={utm} />
                </CtaContainer>
            ) : null}

            {addSmallprint && smallprint && <ContentDisclaimer content={smallprint} />}
        </Section>
    )
}

ContentWithMediaGrid.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    titleArea: PropTypes.shape({
        addTitleArea: PropTypes.bool,
        titlePosition: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string
    }),
    content: PropTypes.string,
    addInfoPerItem: PropTypes.bool,
    itemsRepeater: PropTypes.arrayOf(
        PropTypes.shape({
            mediaType: PropTypes.string,
            image: PropTypes.object,
            video: PropTypes.string,
            title: PropTypes.string,
            content: PropTypes.string
        })
    ).isRequired,
    addCtaOrExpansionToggle: PropTypes.bool,
    buttonType: PropTypes.oneOf(['cta', 'toggle']),
    expandContent: PropTypes.shape({
        initialItems: PropTypes.string,
        moreItemsButtonText: PropTypes.string,
        lessItemsButtonText: PropTypes.string
    }),
    callToAction: PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        target: PropTypes.string
    }),
    addSmallprint: PropTypes.bool,
    smallprint: PropTypes.string,
    className: PropTypes.string,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

ContentWithMediaGrid.defaultProps = {
    backgroundColour: 'gradient',
    titleArea: {
        addTitleArea: true,
        titlePosition: 'centered',
        title: 'Section Title'
    },
    content:
        '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac diam id sem aliquet bibendum. Phasellus id imperdiet ligula. Nunc finibus gravida vestibulum. Ut non iaculis lectus. Aenean placerat nisl sapien, et maximus ligula blandit non. Integer consequat facilisis dui, eu posuere libero luctus non. Praesent pretium, felis eget cursus pharetra, lacus mi convallis neque, nec maximus metus urna et orci. Suspendisse eu tellus laoreet, dapibus nulla id, mollis nibh. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Duis sodales ligula at porta sodales. Ut consequat ipsum ultrices, interdum nulla nec, tempus turpis.</p><p>Quisque euismod quam in gravida tristique. Morbi congue rutrum ullamcorper. Morbi molestie est tincidunt elit sollicitudin pretium. Duis id felis ac mi pulvinar placerat in sit amet tellus. Praesent at ex neque. Sed vitae vestibulum purus, id sodales lacus. Maecenas accumsan et dui vitae lacinia. Mauris nec lacinia lacus. Vestibulum imperdiet, orci quis placerat euismod, ligula tortor tincidunt velit, quis blandit nisi libero eu lorem. Fusce non odio quis ante tempor lobortis. Donec eu tincidunt sem.</p>',
    itemsRepeater: [
        {
            mediaType: 'image'
        },
        {
            mediaType: 'image'
        },
        {
            mediaType: 'image'
        }
    ],
    addCtaOrExpansionToggle: false,
    addSmallprint: false
}

export default ContentWithMediaGrid

export const query = graphql`
    fragment ContentWithMediaGridForPageBuilder on WpPage_Acfpagebuilder_Layouts_ContentWithMediaGrid {
        fieldGroupName
        backgroundColour
        addTitleArea
        title
        description
        content
        addInfoPerItem
        itemsRepeater {
            mediaType
            image {
                id
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 250)
                    }
                }
                altText
                title
            }
            video
            title
            content
        }
        addCtaOrExpansionToggle
        buttonType
        expandContent {
            initialItems
            moreItemsButtonText
            lessItemsButtonText
        }
        callToAction {
            target
            title
            url
        }
        addSmallprint
        smallprint
    }
`
